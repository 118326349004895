/* .hidden,
.hide {
  display: none !important;
} */

/* :root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --hover-color: #28a745;
} */

.border {
  border: 1px solid rgb(11, 10, 10) !important;
  border-radius: 5px;
  background-color: white;
}

.floatLeft {
  float: left !important;
}

.spaceBetween {
  justify-content: space-between !important;
}

.container-fluid {
  width: auto;
}

.txt-primary {
  color: var(--primary-color) !important;
}

.txt-secondary {
  color: var(--secondary-color) !important;
}

.background-primary {
  background-color: var(--primary-color) !important;
}

.background-secondary {
  background-color: var(--secondary-color) !important;
}

.border-color-primary {
  border-color: var(--primary-color) !important;
}

.border-color-secondary {
  border-color: var(--secondary-color) !important;
}

/* ------------------------------------------------------- */

#error {
  color: red;
  display: inline-block;
  /* padding-top: 17px; */
  padding-top: 6px;
  /*change padding*/
}

.border {
  border: 1px solid rgb(11, 10, 10);
  border-radius: 5px;
  background-color: white;
}

.border0 {
  color: black;
  border: 0;
  /*background-color: white;*/
}

.loginSection {
  margin: auto;
}

.title {
  margin: 60px 0;
}

.clubImage {
  width: 50%;
}

.imgSection {
  text-align: center;
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control:focus {
  border-color: rgba(0, 0, 0, 0.09);
}

.form-control,
.input-group-text {
  border-color: rgba(0, 0, 0, 0.09);
}

.input-group-text {
  cursor: pointer;
}

/* ::-webkit-scrollbar {
  display: none;
} */

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 115px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: grid;
  place-items: center;
}

.border-green {
  border-color: var(--primary-color) !important;
}

.greenBg {
  background-color: var(--primary-color);
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  color: rgb(195 195 195) !important;
}

.p-component {
  z-index: 2006 !important;
}

.button-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.button-primary:hover {
  background-color: color-mix(in srgb, var(--primary-color) 90%, black) !important;
  ;
}