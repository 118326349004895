.playerCategoriesContainer {
    font-family: "Karla", sans-serif;
    margin:20px;
}

.playerCategoriesContainer input {
    border:1px solid black;
}

.categoryEditButton {
    margin-left: 10px;
}

.newCategoryArea {
    margin-top:30px;
}

.playerCategoryRow {
    margin-bottom:10px;
}