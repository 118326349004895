.createField {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 24px;
}

.fieldBox {
  border: 2px solid var(--primary-color);
  padding: 10px;
  margin-top: 15px;
  font-size: 16px;
}

.readOnly {
  background: #f8f8f8 !important;
  border: 0 !important;
}

.dropdownStatus {
  margin-top: 15px;
  border-radius: 0 !important;
  border: 2px solid var(--primary-color) !important;
}

.dropdownfield {
  font-size: 25px !important;
  margin-top: 15px;
  padding: 0 !important;
  line-height: inherit;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: black !important;
}

@media screen and (max-width: 1025px) {

  .fieldText,
  .createField {
    font-size: 20px;
  }

  .fieldBox {
    padding: 8px;
  }
}