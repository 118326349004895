.noHeight.active {
  overflow-y: scroll;
  max-height: 365px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 7%), 0 6px 20px 0 rgb(0 0 0 / 14%);
}

.noHeight {
  max-height: 0;
  max-height: 0;
  overflow: hidden;
}

.borderFix {
  border-bottom-width: 1px;
}

.arrowIcon.rotate {
  transform: rotate(180deg);
}

#sideBar {
  width: 15%;
  border-right: 2px solid var(--primary-color);
  min-height: calc(100vh - 175px);
  padding: 30px;
}

.downloadIcon {
  height: 20px;
  width: 20px;
}

.downloadBtn {
  align-content: flex-end;
}

.boxHeader,
.downloadBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 15px;
  border: 2px solid var(--primary-color);
  margin-bottom: 20px;
  cursor: pointer;
}

.listHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 11px 15px;
  border: 2px solid var(--primary-color);
  border-bottom-width: 2px;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.plusIcon {
  font-size: 40px;
  /* color: black; */
  font-weight: 400;
  line-height: 16px;
  position: relative;
  top: -2px;
}

#newList,
#newTutor {
  margin-bottom: 20px;
}

#newList.active,
#newTutor.active {
  background-color: var(--primary-color);
  color: white;
}

#newList.active .plusIcon,
#newTutor.active .plusIcon {
  color: white;
}

.listItem {
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.listItem:last-child {
  border-bottom-width: 2px;
}

.listItem:hover {
  background-color: #dedede;
}

.listItem.active {
  background-color: var(--primary-color);
  color: white;
}

.arrowIcon {
  width: 30px;
}

.clubImage {
  width: 50%;
}

.imgSection {
  text-align: center;
  margin-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#multiplePlayer {
  position: relative;
}

.uploadFileLabel {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 15px;
  left: 0;
  bottom: 0;
  padding: 11px 0px 11px 15px;
  z-index: 2;
}

@media screen and (max-width: 1600px) {

  #sideBar,
  #messageCentre,
  #listCentre {
    padding: 30px;
  }

  .arrowIcon {
    width: 18px;
  }

  .plusIcon {
    font-size: 30px;
  }
}


@media screen and (max-width: 1376px) {
  #sideBar {
    width: 12%;
  }
}

@media screen and (max-width: 1279px) {
  #sideBar {
    /* width: calc(34% - 60px); */
    width: calc(100% - 60px) !important;
    height: unset;
    border: none;
  }

  #listCentre {
    order: 3;
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 320px) {
  #sideBar {
    min-height: 0px;
  }

  .listHeader {
    margin-bottom: 0px !important;
  }

  .downloadBtn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1025px) {
  #sideBar {
    min-height: calc(0px) !important;
  }
}

@media screen and (max-width: 768px) {
  .listHeader {
    margin-bottom: 0px !important;
  }

  .downloadBtn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1025px) {
  #sideBar {
    width: calc(100% - 60px) !important;
    height: unset;
    border: none;
  }

  .buttonClass {
    width: 100%;
  }

  .box {
    width: 100%;
  }

  .boxHeader,
  .downloadBtn {
    align-items: center;
    padding: 10px;
  }

  .listHeader {
    padding: 10px;
    margin-bottom: 10px;
  }

  /* 
  #sideBar,
  #messageCentre,
  #listCentre {
    padding: 30px !important;
  } */
}

/* 
@media screen and (min-width: 1280px) {
  #sideBar,
  #messageCentre,
  #listCentre {
    padding: 25px !important;
  }
} */

/* @media screen and (max-width: 1310px) and (min-width: 1026px) {
  #sideBar,
  #messageCentre,
  #listCentre {
    padding: 25px !important;
  }
} */