#playerCentre {
  padding: 40px;
  width: 45%;
  display: flex;
  flex-direction: column;
}

#listCentre {
  width: 45%;
  padding: 50px;
}

/* transferable value */
.sectionTitle {
  color: black;
  padding-bottom: 2px;
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
}

.transferableLabel {
  color: black;
  text-transform: uppercase;
  /* width: 100%; */
  /* margin-bottom: 10px; */
  font-size: 20px;
}

.transferableUnit {
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rangeSection {
  margin-top: 10px;
}

#transferableValuesSection {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.buttonDiv {
  width: 100%;
}

output {
  font-size: 24px;
  color: var(--primary-color);
  font-style: italic;
}

.slider {
  background: linear-gradient(
    to right,
    var(--primary-color) 0%,
    var(--primary-color) 50%,
    #fff 50%,
    #fff 100%
  );
  border: solid 1px var(--primary-color);
  border-radius: 8px;
  -webkit-appearance: none;
  height: 16px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #000;
  cursor: pointer;
  border-radius: 50%;
}

.collapsible {
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: calc(100% - 36px);
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
}

.active2,
.collapsible:hover {
  /* background-color: var(--primary-color); */
  background-color: color-mix(in srgb, var(--primary-color) 65%, transparent);
}

.content {
  padding: 0px 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: color-mix(in srgb, var(--primary-color) 15%, transparent);
  width: calc(100% - 36px);
}

.collapsible:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active2:after {
  content: "\2212";
}

.transferableContent {
  display: flex;
  flex-wrap: wrap;
  max-height: max-content;
}

.textareaBox {
  width: 75%;
  background: none;
  border: none;
  margin-top: 10px;
  resize: none;
  color: black;
}

.textareaBox.edit {
  border: 1px solid black;
}

textarea:focus-visible {
  outline-color: var(--primary-color);
}

textarea:disabled,
textarea:placeholder {
  color: black;
}

textarea {
  font-family: helvetica;
  font-size: 20px;
}

#boxToBoxSection {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

/* SELECT BOX */
.arrow {
  position: relative;
  height: 15px;
  width: 15px;
}

.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -5px;
  transform: rotate(45deg);
  background-color: #fff;
}

.arrow::after {
  left: 5px;
  transform: rotate(-45deg);
  background-color: #fff;
}

.open .arrow::before {
  left: -5px;
  transform: rotate(-45deg);
}

.open .arrow::after {
  left: 5px;
  transform: rotate(45deg);
}

.select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  margin-bottom: 20px;
}

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  border-width: 0 2px 0 2px;
  border-style: solid;
  border-color: var(--primary-color);
}

.select.stop {
  pointer-events: none;
}

.select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  height: 40px;
  line-height: 40px;
  background: color-mix(in srgb, var(--primary-color) 15%, transparent);
  cursor: pointer;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-color: var(--primary-color);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0;
  background: #fff;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
}

.select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 22px 0 22px;
  font-size: 18px;
  font-weight: 300;
  color: var(--primary-color);
  line-height: 40px;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option.green {
  /* background-color: var(--primary-color); */
  background-color: #006b01;
  color: #fff;
}

.custom-option.orange {
  background-color: #ff9d00;
  color: #fff;
}

.custom-option.red {
  background-color: #ff0000;
  color: #fff;
}

.custom-option:hover {
  cursor: pointer;
  background-color: color-mix(in srgb, var(--primary-color) 55%, transparent);
}

.select__trigger.red {
  background-color: #ff0000;
}

.select__trigger.orange {
  background-color: #ff9d00;
  color: #fff;
}

.select__trigger.green {
  /* background-color: var(--primary-color); */
  background-color: #006b01;
}

.select.open .custom-options {
  position: relative;
}

.boxTitle {
  color: black;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  margin-top: 20px;
}

.boxRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 48%;
  margin-right: 2%;
}

.uploadFileName h4 {
  display: inline-block;
}

.uploadFileName .multipleDataButtonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.playerList .p-dropdown-label {
  padding: 1.5px 5px !important;
}

@media screen and (max-width: 1279px) {
  #playerCentre {
    width: calc(100% - 60px) !important;
    padding: 30px;
  }

  .collapsible {
    font-size: 16px;
  }

  .transferableLabel {
    font-size: 18px;
  }

  .boxTitle {
    font-size: 12px;
  }

  .select__trigger {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) {
  #playerCentre {
    width: calc(100% - 60px) !important;
    padding: 30px;
  }

  .rangeSection {
    display: inline-block;
    margin-right: 6px;
  }
}

/* @media screen and (max-width: 1440px) {
  #playerCentre {
    width: 34%;
  }
} */

@media screen and (max-width: 1557px) {
  #playerCentre {
    width: 34%;
  }
}

@media screen and (max-width: 425px) {
  #playerCentre .play-center-div {
    flex-direction: column;
    align-items: start !important;
  }

  .profileInfoMain {
    margin-top: 10px;
  }
}
