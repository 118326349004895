/* .club_img img {
  width: 175px;
} */

.adminDashboardHeader .p-dropdown {
  border-radius: 0;
}

.adminDashboardHeader .p-dropdown .p-dropdown-label {
  padding: 7px 12px;
  color: #606870 !important;
  text-transform: capitalize;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 7px 12px;
  color: #606870 !important;
  text-transform: capitalize !important;
}

.adminDashboardHeader .p-component {
  z-index: 1006 !important;
}

.adminDashboardHeader .p-dropdown:not(.p-disabled):hover {
  /* border-color: #006b00 !important; */
  border-color: var(--primary-color) !important;
}

.adminDashboardHeader .p-dropdown:not(.p-disabled).p-focus {
  /* border-color: #006b00 !important; */
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem #006b004a !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  /* color: #006b00 !important; */
  color: var(--primary-color) !important;
  /* background: #f1ffee !important; */
  background: color-mix(in srgb, var(--primary-color) 7%, white) !important;
}

@media screen and (max-width: 905px) {
  .adminDashboardHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .adminDashboardDropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }

  .adminDashboardHeader .p-component {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .adminDashboardHeader .search_box {
    width: 100% !important;
  }

  .adminDashboardSearch {
    width: 100%;
  }

  .adminDashboardHeader .text-end {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .adminDashboardHeader .archivedButton {
    justify-content: center !important;
  }
}

@media screen and (max-width: 484px) {
  .adminDashboardCard {
    display: contents !important;
  }
}