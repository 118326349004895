.profileField-inputField {
  border: 1px solid rgba(0, 0, 0, 0.09);
  outline: none;
  border-radius: 5px;
  height: 34px;
  width: 94%;
}

.profile-option-label {
  background-color: #ebebeb;
  color: #707070;
  font-size: 15px;
  padding: 12px;
}

.profile-field-EditIcon {
  color: #ebebeb;
  cursor: pointer;
  margin-left: 12px;
  border-radius: 50%;
  background-color: #aeaeae;
  padding: 2px;
}

.profile-field-EditIcon:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.profile-field-remove {
  color: #ebebeb;
  cursor: pointer;
  margin-left: 9px;
  border-radius: 50%;
  background-color: #aeaeae;
  padding: 2px;
}

.profile-field-remove:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.profile-EditIcon {
  cursor: pointer;
  margin-left: 12px;
}

.profile-EditIcon:hover {
  color: #000;
}

.profile-remove {
  cursor: pointer;
  margin-left: 12px;
}

.profile-remove:hover {
  color: #000;
}

.profile-field-input {
  /* border: 1px solid white; */
  border-bottom: 1px solid #707070;
  background-color: transparent;
  color: #707070;
  padding: 2px;
}

.profile-field-input:focus-visible {
  outline: none;
}

.profile-field-goBtn {
  background-color: var(--primary-color);
  color: #fff;
}

.profile-field-goBtn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.user-profile-editBtn {
  background-color: var(--primary-color);
  color: #fff;
}

.user-profile-editBtn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.user-profile-AddBtn {
  background-color: var(--primary-color);
  color: #fff;
}

.user-profile-AddBtn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

#numberOfOptionGoBtn.go-btn-disable:disabled {
  cursor: no-drop !important;
  pointer-events: all !important;
}

#numberOfOptionGoBtn.go-btn-disable:disabled:hover {
  background-color: #fff;
  border: 1px solid black;
  color: black;
}

#numberOfOptionInputField.disable {
  border: 1px solid black;
}

.checkAndCloseIcon {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .numberOfOptionInputField {
    width: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .numberOfOptionInputField {
    width: 100% !important;
  }

  .profileFieldType {
    margin-bottom: 15px;
  }

  .numberOfOptionButtons {
    display: flex;
  }

  .profile-field-goBtn {
    margin-right: 9px !important;
    margin-left: 0px !important;
  }

  .profile-option-label {
    width: calc(100% - 24px);
  }

  .profileField-options {
    flex-direction: column;
  }

  .profileField-badge {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .profile-option-label {
    justify-content: space-between;
  }

  .profile-field-input {
    width: 100%;
  }

  .checkAndCloseIcon {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 425px) {
  .profile-field-goBtn {
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 375px) {
  .profile-field-goBtn {
    margin-left: 9px !important;
  }
}

@media screen and (min-width: 768px) {
  .backBtnHide {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .profile-backBtnHide {
    display: none !important;
  }
}