.profile-inputField {
  border: 1px solid rgba(0, 0, 0, 0.09);
  outline: none;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-radius: 5px;
  height: 34px;
}

.create-profile-field .profile-line {
  border-bottom: 2px solid #efefef;
  align-items: flex-end;
}

.profile-filed-type {
  font-size: 18px;
}

/* radio button */

.radio-inputs {
  /* display: flex; */
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs>* {
  margin: 6px;
}

.radio-input:checked+.radio-tile {
  border-color: var(--primary-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
}

.radio-input:checked+.radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.radio-input:checked+.radio-tile .radio-icon svg {
  fill: var(--primary-color);
}

.radio-input:checked+.radio-tile .radio-label {
  color: var(--primary-color);
}

.radio-input:focus+.radio-tile {
  border-color: var(--primary-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #006b004a;
}

.radio-input:focus+.radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 48px;
  border-radius: 0.5rem;
  border: 2px solid #dfdfdf;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.65rem;
  height: 0.65rem;
  border: 2px solid #bec7dd;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: var(--primary-color);
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.profile-saveBtn {
  /* position: fixed; */
  bottom: 40px;
}

#profileFieldSaveBtn.save-btn-disable:disabled {
  cursor: no-drop !important;
  pointer-events: all !important;
  color: #707070;
}

#profileFieldSaveBtn.save-btn-disable:hover:disabled {
  background-color: white;
  color: #707070;
  border-color: #494949;
}

.profile-fields-dropdown .p-dropdown-label {
  padding: 6.5px 7.5px !important;
}

.profile-fields-dropdown .p-dropdown {
  margin-bottom: 10px;
}

.profile-fields-dropdown .p-dropdown .p-dropdown-label {
  text-transform: none;
  font-family: "Karla", sans-serif;
}

@media screen and (max-width: 1024px) {
  .radio-inputs {
    flex-wrap: wrap;
  }

  .create-profile-input {
    width: 100% !important;
  }

  .profile-line {
    align-items: flex-end !important;
  }

  .create-profile-saveBtn {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .create-profile-input {
    width: 100% !important;
  }

  .profile-line {
    flex-direction: column;
    align-items: inherit !important;
  }

  .create-profile-saveBtn {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .saveBtn-hide {
    display: none;
  }

  .user-profile-saveBtn {
    margin-bottom: 12px;
    width: 100% !important;
  }

  .profile-saveBtn {
    position: relative;
    bottom: 0;
  }
}

/* @media screen and (min-width: 769px) {
  .profile-saveBtn {
    display: none;
  }
} */