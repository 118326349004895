.addNewClubDiv {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px 0px;
}

.choosePhotoDiv {
  width: 100px;
  height: 100px;
  /* border: 1px solid gray; */
  border-radius: 50%;
  font-size: 10px;
  /* box-shadow: rgba(67, 71, 85, 0.2) 0px 0px 5.6px 0px,
    rgba(90, 125, 188, 0.05) 0px 5.6px 8px 0px; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.inputField {
  border: 1px solid rgba(0, 0, 0, 0.09);
  outline: none;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-radius: 5px;
  height: 34px;
}

.divider {
  color: #b6b3b3;
}

.addClubImage {
  max-width: 65px;
  max-height: 68px;
  width: 80%;
}

.placeItem {
  place-items: center;
}

/* .removeImgDiv {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 0px;
  background-color: #fff;
  border-radius: 100%;
  padding: 5px;
  color: red;
}

.choosePhotoDiv:hover .removeImgDiv {
  display: block;
} */

.chooseRemoveImgDiv {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(27, 27, 27);
  border-radius: 100%;
  display: none;
  gap: 6px;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  color: #fff;
}

.chooseImgDiv,
.removeImgDiv {
  padding: 5px;
  border-radius: 100%;
  color: #fff;
}

.choosePhotoDiv:hover .chooseRemoveImgDiv {
  display: flex;
}

.chooseImgDiv:hover,
.removeImgDiv:hover {
  background-color: #fff;
  color: #000;
}
