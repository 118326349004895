.organizationLabel {
  background-color: white;
  color: black;
  width: 35%;
}

.qualificationLabel {
  /* background-color: tomato; */
  background-color: color-mix(in srgb, var(--primary-color) 65%, white);
  width: 35%;
  text-transform: uppercase;
}

.help-tip:hover span {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1;
  font-size: 10px;
}

.help-tip span {
  display: none;
  text-align: left;
  background-color: #3b434b;
  padding: 5px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
  margin-top: 8px;
}

.help-tip span:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #3b434b;
  left: 10px;
  top: -12px;
}

.help-tip span:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.multi-label {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

/* Add to courses button css */

.Btn {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 29px;
  height: 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--af-white);
}

/* plus sign */
.sign {
  width: 17px;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

/* text */
.text {
  right: 0%;
  width: 0%;
  opacity: 0;
  color: var(--night-rider);
  font-size: 13px;
  font-weight: 600;
  transition-duration: 0.3s;
  white-space: nowrap;
  margin-left: 7px;
}

/* hover effect on button width */
.Btn:hover {
  width: 178px;
  border-radius: 5px;
  transition-duration: 0.4s;
}

.Btn:hover .sign {
  width: 17px;
  transition-duration: 0.3s;
}

/* hover effect button's text */
.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
}

/* button click effect*/
.Btn:active {
  top: 1px;
}

/* alreadyAdded class Style */

.alreadyAddedBtn {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 29px;
  height: 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--af-white);
}

.alreadyAddedBtn:hover {
  width: 156px;
}

.alreadyAddedBtn .text {
  padding: 8px;
}

.alreadyAddedBtn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
}

.alreadyAddedBtn:active {
  top: 1px;
}

.alreadyAddedBtn:hover .sign {
  width: 17px;
  transition-duration: 0.3s;
}

.check-circle {
  color: var(--primary-color);
}

.imgBg {
  background: white;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 6px;
  margin: auto;
}