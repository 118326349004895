.inputWrapper {
  background-color: #efefef;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* border: 1px dashed #dee2e6; */
}

.downloadSampleFileDiv {
  border: 1px dashed #dee2e6;
  width: fit-content;
  color: #5d5d5d !important;
}

/* Hide the default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  background: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(0, 5);
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
  /* background-image: linear-gradient(#b9e9b3, #a8e4a0); */
  background-color: var(--primary-color);
  /* border: solid white; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 0.4em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.scrollable-element::-webkit-scrollbar {
  display: block !important;
  width: 5px !important;
  border-radius: 10px !important;
}

.scrollable-element::-webkit-scrollbar-thumb {
  background-color: rgb(197, 197, 197);
  width: 5px;
}

.scrollable-element::-webkit-scrollbar-track {
  background-color: rgb(230, 229, 229);
  width: 5px;
}

.wrkExpP {
  font-size: 14px;
  color: green;
}

@media screen and (max-width: 1025px) {
  .playerList {
    width: 100% !important;
    overflow-x: auto;
  }
}