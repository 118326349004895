#controlButtons.DropDownControlBtn {
  margin-bottom: 20px !important;
}

.EditTypes {
  padding: 10px;
  cursor: pointer;
  color: #959595;
}

.disabled {
  opacity: 0.3;
  /* color: #404040 !important; */
}

.courseTitle {
  color: var(--primary-color);
  margin-left: 10px;
  font-weight: 500;
  margin-bottom: 6px;
}

.courseInput {
  padding: 6px 20px;
  background-color: #dee2e6;
  border: 1px solid #cecece;
  outline: none;
}

.GoButton {
  display: flex;
  margin-bottom: 10px;
}

.GoLabel {
  padding: 10px;
}

.GoControlButton {
  background-color: var(--primary-color);
  text-align: center;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.courseCommitBtn {
  width: 235px;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid #cecece;
}

.GradingStructure {
  font-size: 14px;
}

@media screen and (max-width: 1025px) {
  .EditTypes {
    padding: 0;
  }

  .courseCommitBtn {
    width: 100%;
  }
}

input {
  border: hidden;
}

/* GoBtn */

.GoControlBtn {
  padding: 6px 25px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  margin: 0px 0px 0 7px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  height: fit-content;
}

#listConfig,
#controlButtons #playerConfig {
  margin-right: 0px;
}

/* .GoControlBtn:hover {
  background-color: white;
  color: #006b00;
} */

.AddCourse {
  display: flex;
}

.ModuleTitle {
  margin-left: 37px;
  color: var(--primary-color);
  margin-bottom: 6px;
  margin-top: 10px;
  font-weight: 500;
}

.Chevron_Right {
  color: #cfcfcf;
}

.ModuleInput {
  margin-left: 37px;
  margin-bottom: 10px;
}

.institutionGrading {
  margin-bottom: 32px;
  margin-bottom: 0;
}

/* .dropDownHeight {
  max-height: 300px;
  overflow: auto;
} */

@media screen and (max-width: 1024px) {
  .GoControlBtn {
    display: flex;
    height: fit-content;
  }

  .courseInput {
    display: flex;
    width: 100%;
  }

  .CourseInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px !important;
  }

  .AddCourse {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .icons {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    width: 100%;
  }

  .ModuleTitle {
    margin-left: 10px;
  }

  .ModuleInput {
    margin-left: 0px;
  }

  .InstitutionEdit {
    display: flex;
    justify-content: flex-end;
  }

  #controlButtons .DropDownControlBtn {
    margin-bottom: 42px !important;
  }

  .EditTypes {
    margin-top: 5px;
  }

  .institutionGrading {
    display: inherit !important;
  }
}

@media screen and (max-width: 768px) {
  .InstitutionEdit {
    display: flex;
    justify-content: flex-end;
  }

  #controlButtons.DropDownControlBtn {
    /* margin-bottom: 42px !important; */
    width: 100%;
  }

  .EditTypes {
    margin-top: 5px;
  }

  .fromCourseInput {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .institutionGrading {
    display: inherit !important;
  }
}

@media screen and (max-width: 1280px) {
  .ModuleTitle {
    margin-top: 0px !important;
  }

  .CourseInfo {
    margin-top: 0px !important;
  }
}