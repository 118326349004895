/* .p-dialog-content {
  margin: 0 auto;
} */

.p-dialog .p-dialog-header {
  padding: 0.5rem;
}

.p-dialog .p-dialog-footer {
  padding: 0 1.5rem 1rem 1.5rem;
}

.p-dialog .p-dialog-content {
  padding: 0 1.5rem 1rem 1.5rem;
}

.ReactCrop__image {
  width: 100%;
  height: 100%;
}
