.profile-card-div {
  border-right: 2px solid #efefef;
}

.user-profile-row {
  height: 100vh !important;
}

.user-profile-card-grid {
  grid-template-columns: repeat(5, 20%);
}

.profile-field-type .p-dropdown-label {
  padding: 7.5px 7.5px !important;
}

.profile-field-type .p-dropdown .p-dropdown-label {
  text-transform: none;
  font-family: "Karla", sans-serif;
}

.organization-profile-card-div .p-tabview-panels {
  padding-left: 0;
  padding-right: 0;
}

/* .organization-profile-card-div .p-tabview-nav-container {
} */

@media screen and (max-width: 1440px) {
  .user-profile-card-grid {
    grid-template-columns: repeat(4, 25%);
  }
}

@media screen and (max-width: 1024px) {
  .user-profile-card-grid {
    grid-template-columns: repeat(3, 33.33%);
  }

  .navigation-step {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 992px) {
  .user-profile-card-grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media screen and (max-width: 768px) {
  .user-profile-card-grid {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 641px) {
  /* .user-profile-card-grid {
    grid-template-columns: 100%;
  } */

  .user-profile-card {
    min-height: 0;
  }
}

@media screen and (max-width: 767px) {
  .user-profile-row {
    height: auto !important;
  }
}
