.CategoryTitle {
  margin-left: 37px;
  color: var(--primary-color);
  padding-bottom: 12px;
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.newCategory {
  padding: 3px;
  position: relative;
}

.SaveButton {
  display: flex;
  /* margin-bottom: 10px; */
}

.Chevron_Right {
  color: #cfcfcf;
  height: 43px;
}

.categoryInput {
  padding: 6px 24px;
  background-color: #dee2e6;
  border: 1px solid #cecece;
  outline: none;
}

.AddOption {
  /* width: 100px; */
  padding: 5px 8px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  margin: 5px 0px 0 36px;
  cursor: pointer;
  /* background-color: var(--primary-color); */
  background-color: white;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  /* color: white; */

  /* position: absolute; */
}

.AddOption:hover {
  background-color: var(--primary-color);
  color: white;
}

.label {
  white-space: nowrap;
  font-size: 14px;
}

.categoryDisabled {
  color: #c3c3c3;
  cursor: no-drop;
}

.EditControlBtn {
  padding: 9px 19px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  margin: 0px 0px 0 7px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
}

.categoryLoader {
  height: 44px;
  padding: 4px 25px 0px 25px !important;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  margin: 0px 0px 0 7px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white !important;
  font-weight: 600;
}

@media screen and (max-width: 320px) {
  .AddOption {
    margin-left: 0px;
  }

  .Chevron_Right {
    display: none;
  }
}

@media screen and (max-width: 1025px) {
  .CategoryError {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 1025px) {
  .AddOption {
    margin-left: 0px;
  }

  .categoryInput {
    width: 100%;
  }

  .newCategory {
    padding: 0px;
    margin-top: 13px;
  }

  .Chevron_Right {
    display: none;
  }
}