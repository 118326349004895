.GradingCategory {
  padding: 3px;
  position: relative;
}

.GradingCategoryTitle {
  margin-left: 37px;
  color: var(--primary-color);
  padding-bottom: 12px;
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.Chevron_Right {
  color: #cfcfcf;
  height: 39px;
}

.GradingCategoryInput {
  padding: 6px 20px;
  background-color: #dee2e6;
  border: 1px solid #cecece;
  outline: none;
  width: 266px;
}

.SaveControlBtn {
  padding: 9px 25px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  margin: 0px 0px 0 7px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white !important;
  font-weight: 600;
  height: fit-content;
}

.GradOption {
  margin: 10px 0 15px 33px;
}

.SaveOption {
  width: 100px !important;
  padding: 5px 8px !important;
  border: 1px solid var(--primary-color) !important;
  text-transform: uppercase;
  margin: 5px 0px 0 38px !important;
  cursor: pointer !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.saveLoader {
  width: 100px !important;
  padding: 5px 8px !important;
  border: 1px solid var(--primary-color) !important;
  text-transform: uppercase;
  margin: 5px 0px 0 38px !important;
  cursor: pointer !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.SaveOption:hover {
  background-color: white !important;
  color: var(--primary-color) !important;
}

.MainGradCategory .disabled:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.AddBtn {
  margin-top: 10px;
}

.disabled {
  cursor: no-drop !important;
}

@media screen and (max-width: 320px) {
  .MainGradCategory {
    position: relative !important;
  }

  .GradingCategoryTitle {
    position: relative !important;
    transform: translate(0);
    margin-left: 0px;
  }

  .GradOption {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .MainGradCategory {
    position: relative !important;
  }

  .GradingCategoryTitle {
    position: relative !important;
    transform: translate(0);
    margin-left: 0px;
  }

  .GradingCategoryInput {
    width: 100%;
  }

  .GradOption {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .MainGradCategory {
    position: relative !important;
  }

  .GradingCategoryTitle {
    position: relative !important;
    transform: translate(0);
    margin-left: 0px;
  }

  .GradingCategoryInput {
    width: 100%;
  }

  .GradOption {
    margin-left: 0px;
  }

  .GradingLabel {
    margin-left: 0px !important;
  }

  .AddBtn {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 1280px) {
  .AddBtn {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 2048px) {
  .AddBtn {
    margin-top: 25px !important;
  }
}