.Course_Title {
  /* color: #006b00; */
  color: var(--primary-color);
  margin-left: 20px;
  font-size: 15px;
}

.Unit_Title {
  color: var(--primary-color);
  margin-left: 20px;
  font-size: 15px;
}

.No_of_units {
  color: var(--primary-color);
  font-size: 15px;
  margin-left: 10px;
}

.Title_Name {
  color: #9e9e9e;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.CourseInput {
  border-bottom: 1px solid #c9c9c9;
  margin-left: 10px;
  font-size: 14px;
}

.CourseIn input {
  color: #9e9e9e;
}

.CourseInput:focus-visible {
  outline: none;
}

.Line {
  border-top: 3px solid;
  margin-top: 9px;
}

.Last_border {
  border: 4px solid rgb(223, 223, 223);
}

.Unit_Line {
  /* width: 367px; */
  border: 1px solid;
}

.UnitInfoInput {
  margin-left: 10px;
  width: 192px;
}

.add-width {
  width: 200px;
}

.icon {
  color: #9e9e9e;
  cursor: pointer;
}

.userIcon {
  color: #9e9e9e;
}

.FileUpload {
  display: none;
}

.IconImg {
  height: 22px;
  width: 22px;
  border-radius: 100%;
}

@media screen and (max-width: 1025px) {
  .UnitInfo {
    display: flex;
    flex-direction: column;
  }
}

/* @media screen and (max-width: 2560px) {
  .UnitInfo {
    display: flex;
    gap: 3.5rem !important;
  }
} */

@media screen and (max-width: 1025px) {
  .Unit_Line {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .CourseInput {
    margin-left: 0px;
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .Course_Title {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .CourseInfoIcon {
    flex-direction: column;
  }

  .CourseUserIcon {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .Course_Title {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  /* .CourseInfoIcon {
    flex-direction: column;
  } */

  .CourseUserIcon {
    /* margin-top: 10px; */
    margin-top: 69px;
  }
}

@media screen and (max-width: 320px) {
  .Unit_Title {
    margin-left: 0px;
    margin-top: 10px;
  }

  .CourseUserIcon {
    margin-top: 12px !important;
  }
}

@media screen and (max-width: 320px) {
  .No_of_units {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .CourseInput {
    margin-left: 0px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .no_of_unit {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 768px) {
  .Unit_Title {
    margin-left: 0px;
    /* margin-bottom: 5px; */
  }

  .UnitInfoInput {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1025px) {
  .Unit_Title {
    margin-left: 0px;
    /* margin-bottom: 5px; */
  }

  .Course_Title {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .ModuleInfoInput {
    margin-left: 0px;
  }

  .CourseInput {
    margin-left: 0px;
  }

  .UnitInfoInput {
    margin-left: 0px;
  }

  .CourseUserIcon {
    margin-top: 54px;
    cursor: pointer !important;
  }

  .no_of_unit {
    margin-top: 10px;
  }
}