.navigation-step-first-title {
  font-size: 17px;
  color: #a7a7a7;
  margin-bottom: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.navigation-step-second-title {
  font-size: 17px;
  color: var(--primary-color);
  margin-bottom: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.navigation-step-rightIcon {
  color: #a7a7a7;
}

.navigation-title-active {
  color: var(--primary-color);
}

.navigation-title-deactivate {
  color: #a7a7a7;
}