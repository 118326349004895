.CreateCourseBtn {
  width: 85%;
  padding: 30px;
  overflow: scroll;
}

.CreateSuperCourseBtn {
  width: 100%;
  height: calc(90vh - 115px);
}

#controlButtons {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 60px; */
  height: 50px;
  margin: 0;
  /* width: 100%; */
  /* margin-top: 66px;
  margin-right: 69px; */
}

.instituteTypeBtn {
  margin: 0 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.createCourse {
  padding: 0px;
}

.showMessageBox {
  margin-left: 280px;
}

@media screen and (max-width: 1025px) {
  #controlButtons {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    /* margin-bottom: 0px; */
  }

  .createCourse {
    padding: 0px;
  }
}

@media screen and (max-width: 320px) {
  .createCourse {
    padding: 0px;
  }

  .CourseText {
    font-size: 22px !important;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .createCourse {
    width: 100%;
    padding: 0px;
  }

  .createCourse {
    margin-top: 0px !important;
  }
}

.CourseText {
  font-size: 25px;
  color: var(--primary-color);
  margin-bottom: 20px;
  justify-content: center;
  /* margin-top: 0px !important; */
  /* margin-left: 50px; */
}

@media screen and (max-width: 1024px) {
  .CreateCourseRow .CourseText {
    display: flex;
    /* justify-content: center; */
    width: 100%;
    margin-left: 0px !important;
  }

  /* .CreateCourseRow .createCourse {
    margin-top: 115px;
  } */
}

@media screen and (max-width: 1025px) {
  .instituteTypeBtn {
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .CreateCourseBtn {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 1025px) {
  #SubDropdown {
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .dropdown-content {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .dropdown-content {
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  #controlButtons {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1025px) {
  .dropbutton {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1025px) {
  .CourseInfo {
    margin-top: 0px !important;
  }

  .CreateCourseRow {
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .CreateCourseBtn {
    overflow: unset;
  }
}

@media screen and (max-width: 1025px) {
  .CreateCourseBtn {
    overflow: unset;
  }
}

@media screen and (max-width: 1280px) {
  .CourseText {
    margin-top: 10px !important;
  }
}