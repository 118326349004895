@media screen and (max-width: 800px) {
  .NavbarMenuButton {
    display: block !important;
    border: 1px solid white;
    padding: 9px !important;
    padding-top: 2px !important;
    padding-bottom: 4px !important;
  }

  .navbarMenuIcon {
    color: white;
  }

  .navSidebar .p-sidebar-content {
    width: auto !important;
  }

  .navbar-line {
    color: inherit;
    border: 0;
    border-top: 1px solid;
    margin: 0px;
  }
}

.navbarIconImg {
  height: 20px;
  width: 20px;
}

.p-sidebar-mask {
  z-index: 9999 !important;
}

.navSidebarTabActive {
  background-color: #dbf1d6;
}

.navSidebarTabText {
  color: black !important;
}
