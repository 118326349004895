@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300&family=Poppins:ital,wght@0,300;1,200&display=swap");

.text-green {
  color: var(--primary-color) !important;
}

.back_btn {
  right: 20px;
  cursor: pointer;
}

.img-circle {
  max-width: 75px !important;
  max-height: 70px;
  margin-top: -30px;
}

.search_box {
  width: 290px;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.p-multiselect {
  min-width: 15rem;
  /* border-radius: 50rem !important; */
  border-radius: 8px !important;
  margin-top: 0 !important;
}

.multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect-custom .country-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect-custom .country-item-value img.flag {
  width: 17px;
}

.p-multiselect .p-multiselect-label {
  padding: 8px 15px 8px 15px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-color);
  background: #f1ffee;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: #ffffff;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: var(--primary-color)
}

.p-multiselect:not(.p-disabled):hover {
  border-color: var(--primary-color)
}

.filterDiv {
  border: 1px solid #ced4da !important;
  border-radius: 8px;
}

.filterDiv .filterText {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* .dropdownFilter .dropdown-menu{
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);
  border: 0 solid rgba(0, 0, 0, 0.15);
  animation: slideDown 300ms ease forwards;
  transform-origin: top center;
  top: 41px !important;
  right: auto !important;
  border-radius: 10px;
  left: 0 !important;
} */

.dropdownFilter {
  position: relative;
  /* display: inline-block; */
  padding-bottom: 6px;
  font-size: 22px;
}

.dropdownFilter .dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 12px;
  z-index: 999;
  color: black;
  /* left: -166px; */
  right: -554px;
  border-radius: 6px;
  margin-top: 6px;
  width: max-content;
  animation: slideDown 300ms ease forwards;
  transform-origin: top center;
  top: 41px !important;
}

.dropdownDateRangerPicker .dropdown-content {
  left: 0;
  animation: none;
  transform-origin: none;
  top: 0;
}

.multiselectStatus {
  font-size: 12px;
}

.multiselectStatus .p-multiselect .p-multiselect-label {
  padding: 4px 15px 4px 15px !important;
}

.multiselectStatus .p-multiselect {
  border-radius: 8px;
}

.statusDiv {
  border: 1px solid #e2e2e2;
  border-radius: 8px !important;
}

.smallFont {
  font-size: small;
}

.filterElementUIDiv {
  position: relative;
}

.removeFilterElement {
  position: absolute;
  top: 0;
  right: 3px;
}

.sidebarInnerDiv {
  box-sizing: border-box !important;
  width: 94%;
}

.sidebarMainDiv .multiselect-custom {
  width: 100%;
}

.p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-color)4a !important;
}

@media only screen and (max-width: 1192px) {
  .dropdownFilter .dropdown-content {
    left: -160px;
  }
}

@media only screen and (max-width: 1090px) {
  .statusFilterUI {
    flex-direction: column;
    border: 1px solid #e2e2e2;
    padding: 18px;
    align-items: start !important;
  }

  .deleteFilterElement {
    align-self: flex-end;
    display: none;
  }

  .removeFilterElement {
    display: block !important;
  }

  .dropdownFilter .dropdown-content {
    left: 0;
  }

  .statusDiv {
    margin-bottom: 0.5rem;
    margin-left: 0 !important;
  }

  .sidebarOuterDiv {
    display: none;
  }

  .menuButton {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .statusDiv {
    flex-direction: column;
    border: 1px solid #e2e2e2;
    padding: 18px;
    align-items: start !important;
    width: -webkit-fill-available;
  }

  .statusDiv>div {
    margin-top: 0.5rem;
    width: -webkit-fill-available;
  }

  .removeFilterElement {
    top: 5px;
  }

  .filterDiv {
    margin-left: 0 !important;
    justify-content: space-between;
  }

  .statusDiv #multiSelectedClubDiv {
    padding-left: 0 !important;
  }

  .multiselect-custom {
    width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 641px) {
  .sidebarInnerDiv {
    box-sizing: border-box !important;
    width: 92%;
  }
}

@media only screen and (max-width: 481px) {
  .dropdownFilter .dropdown-content {
    left: -13px;
  }

  .headerDiv {
    flex-direction: column-reverse;
  }

  .headerDivBackButton {
    margin-top: 10px;
  }

  .sidebarInnerDiv {
    box-sizing: border-box !important;
    width: 90%;
  }
}

@media only screen and (max-width: 390px) {
  .dropdownFilter .dropdown-content {
    left: -138px;
  }

  .sidebarInnerDiv {
    box-sizing: border-box !important;
    width: 88%;
  }
}

@media only screen and (max-width: 577px) {
  .smallFont {
    display: none;
  }
}