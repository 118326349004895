.coursesTab .p-tabview .p-tabview-nav {
  border: 0px;
}

.coursesTab .nav-pills .nav-link {
  border-radius: 0px !important;
  padding-left: 0;
  padding-right: 0;
  margin-right: 16px;
  margin-left: 16px;
}

.coursesTab .nav-pills .nav-link.active {
  border-bottom: 3px solid var(--primary-color) !important;
  background: 0 0;
  color: var(--primary-color) !important;
  border-radius: 0px !important;
}

.coursesTab .nav-link {
  font-weight: 600 !important;
  font-size: 20px;
  color: #4e4e4e !important;
}

.coursesFilter .p-dropdown {
  width: 282px;
}

.p-dropdown-filter {
  box-sizing: border-box !important;
}

.p-dropdown .p-dropdown-label {
  text-transform: capitalize;
}

.no-Result-Found {
  height: 59.5vh;
  justify-content: center;
}

.noResultImg {
  height: 170px;
  width: 170px;
}

.noResultsFound {
  font-size: 25px;
  color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .coursesFilter {
    /* display: flex; */
    flex-direction: column;
  }

  .p-dropdown {
    width: 100% !important;
  }

  .coursesTab .nav {
    flex-wrap: inherit;
    overflow-x: auto;
    /* flex-direction:column; */
  }

  .instituteTypeBtn {
    width: 100%;
  }

  .DropDownControlBtn {
    margin-bottom: 20px !important;
  }

  #SubDropdown {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .noResultsFound {
    font-size: 18px;
    flex-direction: column;
  }

  .assign-course-link {
    display: flex;
    justify-content: center;
  }
}