.profile-card-title {
  color: #757a8d;
  font-size: 15px;
}

.user-profile-RemoveIcon,
.user-profile-EditIcon {
  color: #757a8d;
  cursor: pointer;
}

.user-profile-RemoveIcon:hover {
  color: #dc3545;
}

.user-profile-EditIcon:hover {
  color: var(--primary-color);
}

.user-profile-card-body {
  padding: 11px 30px 30px 30px;
}

.profile-card-header {
  padding: 10px 30px 0 30px;
}

.profile-card-footer {
  padding: 0px 30px 10px 30px;
}

/* inputBox style */

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  outline: 0;
  font-size: 14px;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.user-profile-saveBtn {
  background-color: var(--primary-color);
  color: white;
}

.user-profile-saveBtn:hover {
  background-color: var(--primary-color);
  color: white;
}

/* new style */

.profile-card-div {
  box-sizing: border-box !important;
}

/* .user-profile-card {
  border-right: 2px solid #efefef;
  min-height: calc(100vh - 110px);
} */