#controlButtons .controlBtn {
  /* padding: 12px 15px; */
  padding: 12px 11px;
  /* border: 2px solid #006b00; */
  border: 2px solid var(--primary-color);
  text-transform: uppercase;
  margin: 0px 5px !important;
  cursor: pointer;
}

#listConfig,
#controlButtons #playerConfig {
  margin-right: 0px;
}

.controlBtn:hover {
  background-color: var(--primary-color);
  color: white;
}

.disable {
  /* Note: pointer-events not supported by IE10 and under */
  pointer-events: none;
  opacity: 0.4;
}

@media screen and (max-width: 1025px) {
  #controlButtons .controlBtn {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  /* add new style  */
  #playerCentre {
    padding: 30px !important;
    width: 34% !important;
  }
}

/* add new style */

@media screen and (max-width: 1305px) {
  #controlButtons .controlBtn {
    padding: 12px 11px !important;
  }
}

@media screen and (max-width: 1025px) {
  #controlButtons .control-center-btn {
    margin-bottom: 10px !important;
  }
}

/* @media screen and (max-width: 1310px) and (min-width: 1026px) {
  #controlButtons .controlBtn {
    padding: 12px 11px !important;
  }

  #playerCentre {
    padding: 20px !important;
  }

  #sideBar {
    width: 30% !important;
  }
} */
/* ------------------------------ */

@media screen and (min-width: 1280px) and (max-width: 1308px) {
  #playerCentre {
    padding: 20px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  #playerCentre {
    padding: 20px !important;
  }
}

/* @media screen and (width: 1280px) {
  #sideBar {
    width: 30% !important;
  }
} */

@media screen and (min-width: 1440px) and (max-width: 1497px) {
  #controlButtons .controlBtn {
    padding: 12px 7px !important;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1359px) {
  #controlButtons .controlBtn {
    padding: 12px 7px !important;
  }
}