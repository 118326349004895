.img-circle {
  max-width: 75px !important;
  max-height: 70px;
  margin-top: -30px;
}

.card_div {
  width: 316px;
  box-sizing: border-box !important;
  cursor: pointer;
}

.card {
  /* max-width: 250px; */
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
  /* min-height: 401px; */
  height: 100% !important;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  /* box-shadow: 0 0px 40px rgb(0 0 0 / 5%);
  transition: all 0.3s ease-out;
  border: 2px solid transparent; */
}

.clubCard {
  /* box-shadow: 0 0px 40px rgb(0 0 0 / 5%) !important; */
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  transition: all 0.3s ease-out !important;
}

.clubCard:hover {
  cursor: pointer;
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px rgba(189, 253, 207, 0.11) !important;
}

.status {
  font-size: 13px;
}

.poppins {
  font-family: "Karla", sans-serif;
}

.img-wrapper {
  min-height: 172px;
  width: 100%;
}
.club-wrapper {
  min-height: 55px;
}

#btn-back-to-top {
  bottom: 20px;
  right: 20px;
  display: none;
  background-color: #006b00;
}

.profile-img {
  min-height: 202px;
  background-color: #efefef;
  object-fit: contain;
}

.club_image_card {
  min-height: 150px;
  object-fit: contain;
  max-height: 84px;
}

.form-control:focus {
  box-shadow: none !important;
}

.search_box {
  width: 290px;
  border: 1px solid #ced4da !important;
  border-radius: 6px;
}

.menu_vertical_icon {
  width: 25px;
  /* position: absolute; */
  top: 10px;
  right: 8px;
}

.cardBodyAddCard {
  /* display: grid;
  place-items: center;
  cursor: pointer; */
  width: 50% !important;
  margin: 0 auto;
  cursor: pointer;
}

.tutor_card_div {
  width: 226px;
  box-sizing: border-box;
}

.tutorImgDiv {
  width: 226px;
  height: 226px;
}

.archive {
  filter: grayscale(1);
}

.badgeDiv {
  position: absolute;
  top: 2.5px;
  left: 4.5px;
}

@media only screen and (max-width: 992px) {
  .card_div {
    width: 280px;
  }
}

@media only screen and (max-width: 768px) {
  .search_box,
  .search_boxDiv {
    width: 100%;
  }

  .card_div {
    width: 280px;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
}

@media only screen and (max-width: 641px) {
  .card_div {
    width: 230px;
  }
}

@media only screen and (max-width: 481px) {
  .card_div {
    width: 100%;
  }
}
