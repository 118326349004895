.UnitInfoInput {
  border-bottom: 1px solid #c9c9c9;
  margin-left: 10px;
  font-size: 14px;
}

.ModuleInfoInput {
  border-bottom: 1px solid #c9c9c9;
  margin-left: 10px;
  font-size: 14px;
  width: 180px;
}

.UnitInfoInput:focus-visible {
  outline: none;
}

.ModuleInfoInput:focus-visible {
  outline: none;
}

.UnitCancelBtn {
  padding: 10px;
  background-color: white;
  border: 1px solid black;
  margin-left: 10px;
}

.unitAddBtn {
  padding: 10px;
  /* color: white; */
  background-color: white;
  /* border: none; */
  border: 1px solid black;
  margin-left: 10px;
  height: fit-content;
}

.UnitCancelBtn:hover {
  transition: 0.3s;
  background-color: #6c757d;
  color: white;
}

.UnitSaveBtn {
  padding: 10px;
  color: white;
  /* background-color: #006b00; */
  background-color: var(--primary-color);
  border: none;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.Unit_Line {
  margin-top: 9px;
}

.icon {
  font-size: 14px;
}

.userIcon {
  background-color: #c7c7c7;
  border-radius: 100%;
  padding: 2px;
  color: white !important;
}

.courses-title-name {
  margin-bottom: 6px;
}

@media screen and (max-width: 1025px) {
  .unitBtn {
    display: flex;
    justify-content: center !important;
  }

  .unitUserIcon {
    margin-top: 26px;
  }
}

@media screen and (max-width: 1025px) {
  .CourseIn {
    /* width: 100%; */
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .UnitInfoInput {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1440px) {
  .No_of_units {
    margin-left: 10px;
  }
}

@media screen and (max-width: 7680px) {
  .UnitInfoInput {
    margin-left: 0px;
  }
}

@media screen and (max-width: 320px) {
  .ModuleInfoInput {
    margin-left: 0px;
  }

  .UnitInfoIcon {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .ModuleInfoInput {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .Course_Title {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .UnitInfoInput {
    margin-left: 0px;
  }

  .deleteBtn {
    margin-top: 27px !important;
    margin-bottom: 0px !important;
  }
}