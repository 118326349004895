.addNewClubDiv {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px 0px;
}

.choosePhotoDiv {
  width: 100px;
  height: 100px;
  /* border: 1px solid gray; */
  border-radius: 50%;
  font-size: 10px;
  /* box-shadow: rgba(67, 71, 85, 0.2) 0px 0px 5.6px 0px,
    rgba(90, 125, 188, 0.05) 0px 5.6px 8px 0px; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background-color: #fff;
  cursor: pointer;
}

.inputField {
  border: 1px solid rgba(0, 0, 0, 0.09);
  outline: none;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-radius: 5px;
  height: 34px;
}

.divider {
  color: #b6b3b3;
}

.addClubImage {
  max-width: 65px;
  max-height: 68px;
  width: 80%;
}

.placeItem {
  place-items: center;
}

.addNewClubDiv .p-dropdown-label {
  font-family: "Karla", sans-serif !important;
}

.addNewClubDiv .p-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.addNewClubDiv .p-dropdown-label {
  padding: 7.5px 7.5px !important;
  text-transform: capitalize;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-transform: capitalize;
}

.p-dropdown:not(.p-disabled).p-focus {
  /* border-color: #006b00 !important; */
  border-color: var(--primary-color) !important;
  /* box-shadow: 0 0 0 0.2rem #006b004a !important; */
  box-shadow: 0 0 0 0.2rem var(--primary-color-shadow) !important;
}

.p-dropdown:not(.p-disabled):hover {
  /* border-color: #006b00 !important; */
  border-color: var(--primary-color) !important;
}

.btn-create-club:hover {
  background-color: color-mix(in srgb, var(--primary-color) 90%, black) !important;
}