#createSection {
  display: flex;
  flex-direction: column;
}

.fieldText {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 25px;
  border: 1px solid transparent;
  overflow-x: scroll;
}

@media screen and (max-width: 1025px) {
  .fieldText {
    font-size: 20px;
  }
}
