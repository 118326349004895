.CardExistingControlBtn {
  padding: 30px;
  width: 85%;
}

.more_vertical {
  color: white;
  margin-right: 10px;
}

.Card_img {
  width: 100px;
  height: 100px;
}

.Title_name {
  color: white;
  padding-left: 3px;
  padding-right: 3px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  height: 60px;
}

.static_name {
  color: white;
}

.RightIcon {
  color: #a7a7a7;
}

.EditRightIcon {
  /* color: #006b00; */
  color: var(--primary-color);
}

.CardDynamicTitle {
  color: #a7a7a7;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
}

.CardExistingText {
  font-size: 20px;
  color: #a7a7a7;
  margin-bottom: 20px;
  cursor: pointer;
}

.CardStaticTitle {
  /* color: #006b00; */
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
}

.CourseCardInfo {
  /* background-color: #006b00; */
  background-color: var(--primary-color);
  /* margin: 35px 20px 0 0; */
  width: 235px;
  height: 235px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  display: grid;
  align-items: center;
  position: relative;
}

.course-card-center {
  background-color: var(--primary-color);
  /* background-color: #006b00; */
  /* margin: 35px 20px 0 0; */
  width: 235px;
  height: 255px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  display: grid;
  align-items: center;
  position: relative;
}

.CardTitle {
  margin-top: 10px;
}

.cardDropDown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1;
  z-index: 1;
}

.CardDropDownBtn {
  background-color: white;
  text-align: center;
  /* color: #006b00; */
  color: var(--primary-color);
  /* padding: 16px; */
  font-size: 16px;
  cursor: pointer;
  /* min-width: 100px; */
  /* width: 100%; */
  margin-bottom: 0px !important;
}

.CardControlBtn {
  padding: 10px;
  border: 0px;
  /* border: 1px; */
  /* border: 2px solid #006b00; */
  text-transform: uppercase;
  /* margin: 0px 10px; */
  cursor: pointer;
}

.CardControlBtn:hover {
  /* background-color: #006b00; */
  background-color: var(--primary-color);
  color: white;
}

.CardSubBtn {
  /* border: 1px solid #006b00; */
  /* border-top: 1px solid #006b00;
  border-bottom: 1px solid #006b00; */
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.CardSubBtnSecond {
  /* border-bottom: 1px solid #006b00; */
  border-bottom: 1px solid var(--primary-color);
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.unitCard {
  /* display: flex; */
  justify-content: center;
  gap: 16px;
  margin-top: 25px;
}

.unit-card-badge {
  position: absolute;
  width: 100%;
  bottom: 9px;
}

.CourseInfo {
  margin-top: 10px;
}

.assign-course-link {
  text-decoration: underline;
  color: #4a4a4a;
  cursor: pointer;
  margin-left: 10px;
}

.assign-course-link:hover {
  /* color: #006b00; */
  color: var(--primary-color);
}

/* .LoaderSpin {
  position: relative !important;
  top: 30% !important;
} */

@media screen and (max-width: 1025px) {
  .CardTitle {
    margin-top: 120px !important;
  }

  .courseCardInfoRow {
    display: contents;
  }

  .courseCardInfoCol {
    width: 100%;
    padding: 0px;
  }
}

@media screen and (max-width: 1025px) {
  .unitCard {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
  }

  /* .LoaderSpin {
    position: relative !important;
    margin-top: 20%;
    margin-bottom: 10%;
  } */
}

@media screen and (max-width: 1025px) {
  .CourseCardInfo {
    margin: 35px 0px 0 0 !important;
  }

  .courseCardInfoRow {
    margin: 0px;
  }

  .CourseInfo {
    padding: 0px;
  }
}

@media screen and (max-width: 320px) {
  .CardTitle {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 320px) {
  .EditRightIcon {
    height: 20px;
    width: 20px;
  }
}

@media screen and (max-width: 320px) {
  .RightIcon {
    height: 20px;
    width: 20px;
  }

  /* .LoaderSpin {
    position: relative !important;
    margin-top: 20%;
    margin-bottom: 10%;
  } */
}

@media screen and (max-width: 768px) {
  .RightIcon {
    height: 30px;
    width: 30px;
  }

  .courseCardInfoRow {
    display: contents;
  }

  .courseCardInfoCol {
    padding: 0px;
    width: 100%;
  }

  .CourseInfo {
    margin-top: 15px !important;
    padding: 0px;
  }

  /* .LoaderSpin {
    position: relative !important;
    margin-top: 20%;
    margin-bottom: 10%;
  } */
}

@media screen and (max-width: 320px) {
  .RightIcon {
    height: 29px;
    width: 25px;
  }

  .CourseInfo {
    padding: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .EditRightIcon {
    height: 23px;
    width: 25px;
  }
}

@media screen and (max-width: 320px) {
  .EditRightIcon {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 320px) {
  .CardExistingText {
    margin-bottom: 0px;
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .CardDynamicTitle {
    margin-bottom: 0px;
    font-size: 20px;
  }

  .CardExistingText {
    margin-bottom: 0px;
    font-size: 20px;
  }
}

@media screen and (max-width: 320px) {
  .CardDynamicTitle {
    /* margin-bottom: 0px; */
    font-size: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .CardTitle {
    margin-top: 20px;
  }
}

@media screen and (max-width: 425px) {
  .CardTitle {
    /* margin-top: 20px; */
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1299px) {
  .courseCardInfoRow {
    display: flex;
    flex-direction: column;
  }

  .CourseInfo {
    width: 100%;
    margin-top: 25px;
  }

  .DropDownControlBtn {
    flex-direction: column;
  }
}

@media screen and (max-width: 542px) {
  .CardTitle {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 1024px) {
  .course-cardInfo-title {
    margin-top: 0px !important;
  }

  .course-cardInfo-title .CardExistingText {
    font-size: 19px;
    margin-bottom: 0px;
  }

  .course-cardInfo-title .RightIcon {
    height: 28px;
    width: 30px;
  }

  .CardDynamicTitle {
    font-size: 19px;
  }

  .course-cardInfo-title .CardStaticTitle {
    font-size: 19px;
  }
}

@media screen and (max-width: 425px) {
  .course-cardInfo-title .CardExistingText {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .course-cardInfo-title .RightIcon {
    height: 24px;
    width: 30px;
  }

  .CardDynamicTitle {
    font-size: 17px;
  }

  .course-cardInfo-title .CardStaticTitle {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  .course-cardInfo-title {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }

  .ExistingControlBtn {
    width: auto;
  }
}

@media screen and (max-width: 816px) {
  .course-cardInfo-title {
    flex-wrap: wrap;
  }
}