.userProfile {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--primary-color);
}

.EditProfileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* .profilepic {
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic:hover .profilepic__image {
  opacity: 0.5;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
} */

div.upload {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
  height: 30px;
  padding: 3px 0px 3px 3px;
  position: relative;
  width: auto;
}

div.upload:hover {
  opacity: 0.95;
}

div.upload input[type="file"] {
  display: input-block;
  width: 100%;
  height: 30px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.uploadButtonImage {
  background-color: var(--primary-color);
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-right: 15px;
  width: auto;
  padding: 0 20px;
  box-sizing: content-box;
}

.fileName {
  font-family: Arial;
  font-size: 14px;
}

.upload+.uploadButtonImage {
  height: 38px;
}

.profileImgDiv {
  position: relative;
}

.chooseRemoveImgProfileDiv {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(27, 27, 27);
  border-radius: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  color: #fff;
}

.removeProfileImgDiv {
  padding: 5px;
  border-radius: 100%;
  color: #fff;
}

.profileImgDiv:hover .chooseRemoveImgProfileDiv {
  display: flex;
}

.removeProfileImgDiv:hover {
  background-color: #fff;
  color: #000;
}