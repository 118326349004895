.fullwidth {
  width: 100%;
}

.header-image-wrapper {
  overflow-x: hidden;
}

#headerImg {
  width: 100%;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

#controlHeader {
  display: flex;
  position: relative;
  min-width: 100vw;
  height: 115px;
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 99999; */
  /* overflow-x: hidden; 789 */
}

#logOut {
  position: absolute;
  right: 12px;
  top: 52%;
  color: white;
  cursor: pointer;
}

#players {
  position: absolute;
  right: 130px;
  top: 60%;
  color: white;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.allPlayersNav {
  font-size: 20px;
  letter-spacing: 1px;
  height: 40px;
}

.profileDivNav {
  width: 45px;
  height: 45px;
}

.profileName_p {
  font-size: 18px;
  margin-bottom: -5px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.profileNavDesignation {
  /* font-size: 12px; */
  color: rgb(202, 196, 196);
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.dropdownProfile {
  position: relative;
  /* display: inline-block; */
  padding-bottom: 6px;
}
.activeNavBar {
  position: relative;
}

.activeNavBar::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: " ";
  border-bottom: 3px solid #fff;
}

.homeIcon {
  position: relative;
  margin-top: 5px !important;
}

.homeIcon::after {
  position: absolute;
  bottom: 4px;
  left: 8px;
  content: " ";
  border: 3px solid #fff;
  border-radius: 50%;
}

.dropdownProfile .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 12px;
  z-index: 9999;
  color: black;
  right: 0;
  border-radius: 6px;
  margin-top: 6px;
  width: max-content;
}
/* .dropdown-content div {
  position: absolute;
} */

.dropdownProfile:hover .dropdown-content {
  display: block;
  color: black;
}

.dropdown-content .dropdownEmail {
  font-size: 15px;
}

.dropdown-nav-item:hover {
  background-color: rgb(232, 232, 235);
  border-radius: 3px;
}
.mainNavbarClubAdmin {
  width: auto !important;
}

@media only screen and (max-width: 481px) {
  #players {
    top: 70%;
    left: 10px;
  }
  #logOut {
    top: 60%;
  }
  .profileName {
    display: none;
  }

  #controlHeader,
  #controlHeader img#headerImg {
    height: 122px;
  }
}

@media only screen and (max-width: 800px) {
  .navbarLinkShow {
    display: none !important;
  }

  #logOut.MainNavbar {
    width: 96% !important;
    justify-content: space-between !important;
  }
}
