.buttonClass {
  width: 30%;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  text-transform: uppercase;
  padding: 16px;
  font-size: 20px;
  font-weight: 600;
  float: right;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.greenButton {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border: none;
}

.greenButton:hover,
.archivedButton:hover {
  color: #fff !important;
  /* background-color: #065606 !important; */
  /* background-color: #006b01 !important; */
  background-color: var(--primary-color) !important;
}

#save.buttonClass {
  background-color: #000000;
  color: #fff;
  /* border: none; */
}

.deleteBtn.buttonClass {
  background-color: #ff0000;
  color: #fff;
  border: none;
}

.adminBtn.buttonClass {
  background-color: #ffa500;
  color: #fff;
  border: none;
}

.uploadButton {
  display: block;
  margin-right: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border-color: var(--primary-color);
  padding: 12px 15px;
  text-transform: uppercase;
}