body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: helvetica;
  max-height: 100vh;
  overflow-y: scroll;
  /* background-color: #f3f6fd; */
}

* {
  box-sizing: revert !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

.bebas {
  font-family: bebas-neue-pro, sans-serif;
}

.alternate-gothic {
  font-family: alternate-gothic-no-3-d, sans-serif;
}
