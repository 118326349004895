.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--primary-color) !important;
}

.transferableLabelDiv {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.enableSection {
  gap: 10px;
  margin: 20px 0;
}

.enableTitle {
  font-size: 16px;
  font-weight: 600;
}

.subHeaderTransferable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonTransferable {
  margin: 20px 0;
}

.buttonTransferable .p-button {
  padding: 6px;
  border-color: var(--primary-color);
  color: black;
  background-color: #ffff;
  /* font-size: 15px; */
}

.buttonTransferable .p-button .p-button-label {
  font-weight: 400;

}

.buttonTransferable .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.p-dialog-visible {
  z-index: 9999;
}

.subHeaderTransferable .p-component,
.transferableContent .p-component {
  z-index: 1000 !important;
}

.textAreaBox {
  width: 100%;
  margin-top: 30px;
}

.p-slider .p-slider-handle {
  border-color: var(--primary-color) !important;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover,
.p-slider .p-slider-range {
  background-color: var(--primary-color) !important;
}

.transferableContent_ {
  max-height: max-content;
}