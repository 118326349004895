#controlCentreContainer {
  display: flex;
  flex-direction: row;
}

.routes_background {
  background-image: url("assets/images/ROUTES-REPEATED.jpg");
  height: 88vh;
}

.loginSection {
  margin: 0 auto;
}

#playerCentre {
  padding: 40px;
  width: 45%;
  display: flex;
  flex-direction: column;
}

#createSection {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1279px) {
  #controlCentreContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 641px) {

  /* .loginSection {
    width: 100vw !important;
  } */
  .loginSection .loginFormDiv {
    width: inherit !important;
  }
}