.work-exp-card {
  width: 200px !important;
  /* height: 100px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 22px 20px;
  cursor: pointer;
}

.work-exp-card:hover {
  background-color: rgba(243, 243, 243, 0.63);
}

.create-work-exp .inputLabel {
  font-size: 18px;
}

#workExperienceTitle {
  font-size: 19px;
}

.create-work-exp .p-inputtext {
  padding: 5px;
}

.work-card {
  width: 250px !important;
  background-color: #006B00;
  color: #fff;
  position: relative;
}

.work-card .icon {
  position: absolute;
  top: 6px;
  right: 10px;
}