.menu_vertical_icon {
  width: 25px;
  /* position: absolute; */
  top: 10px;
  right: 8px;
}

.iconColor {
  color: #515151 !important;
  padding: 3px;
}

.iconColor:hover,
.iconColor:active,
.iconColor:focus {
  color: #515151 !important;
  /* background-color: #f5f3f3; */
  background-color: rgb(222 219 219 / 32%) !important;
  border-radius: 50%;
}

.dropdownClub {
  /* position: absolute;
  top: 6.5px;
  right: 10px; */
  cursor: pointer;
}

.tutorDropdown {
  position: absolute;
  top: 6.5px;
  right: 10px;
}

.dropdownClub.menu_vertical_icon {
  top: 3.5px;
  right: 1.8px;
}

.iconColor.menu_vertical_icon {
  color: #b3b3b3 !important;
}

.iconColor.menu_vertical_icon:hover,
.iconColor.menu_vertical_icon:active,
.iconColor.menu_vertical_icon:focus {
  color: #ffffff !important;
  background-color: #515151;
  border-radius: 50%;
}

/* .dropdownClub:hover {
  background-color: #f8f8f8;
  border-radius: 25px;
} */

.dropdownClub .dropdown-menu {
  /* transform: translate3d(0px, 0px, 10px);
  transform-origin: top;
  transition: transform 0.3s ease-in-out; */
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);
  border: 0 solid rgba(0, 0, 0, 0.15);
  animation: slideDown 300ms ease forwards;
  transform-origin: top center;
  top: 28px !important;
  right: 0 !important;
  border-radius: 10px;
  left: auto !important;
}

.dropdownItem {
  width: auto;
  display: flex;
  align-items: center;
  color: #000 !important;
  /* padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; */
  border-radius: 4px;
  font-size: 14px;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownDivider {
  border-color: rgb(144 144 144 / 18%) !important;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
