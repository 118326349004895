.cards-list {
  /* width: 100%; */
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.CourseCard {
  /* background-color: #006b00; */
  background-color: var(--primary-color);
  margin: 35px 20px 0 0;
  width: 220px;
  height: 220px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  display: grid;
  align-items: center;
  position: relative;
}

.CourseCard .card_title {
  text-align: center;
  color: white;
  /* font-family: sans-serif; */
  font-size: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* text-transform: capitalize; */
  /* text-transform: lowercase; */
}

.removeInputIcon {
  /* position: absolute; */
  /* top: 26px; */
  color: black !important;
  /* right: 3px; */
}

.CourseCard:hover {
  /* transform: scale(0.98); */
  cursor: pointer;
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px rgba(189, 253, 207, 0.11) !important;
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

.CourseCard .iconColor:hover,
.iconColor:active,
.iconColor:focus {
  color: #515151 !important;
  background-color: #787878;
  border-radius: 50%;
}

/* @media all and (max-width: 500px) {
  .cards-list {
    display: flex;
    justify-content: center;
  }
} */

@media screen and (max-width: 1025px) {
  .cards-list {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
  }
}

@media screen and (max-width: 534px) {
  .CourseCard {
    margin: 35px 0px 0 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .CardStaticTitle {
    font-size: 20px !important;
  }

  .CardTitle {
    margin-bottom: 30px;
  }
}