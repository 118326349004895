.coursesTab {
  overflow: scroll;
}

.courseTab {
  border: 2px solid #9b9898;
  padding: 3px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.courseTab:hover {
  /* background-color: #006B00; */
  background-color: var(--primary-color);
  color: #fff;
}

.selectedCourseTab {
  /* background-color: #006B00; */
  background-color: var(--primary-color);
  color: #fff;
  border: 2px solid var(--primary-color);
}

.workExperience {
  /* border: 2px solid #dbdada; */
  /* height: 70vh; */
  /* overflow: scroll; */
  margin-top: 1.4rem;
}

.workLogs {
  height: 60vh;
  overflow: scroll;
}

.workExperience .p-carousel-container {
  align-items: center;
}

.workExperienceLogs {
  padding: 10px 30px;
}

.card__progress progress {
  width: 100%;
  height: 4px;
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
}

.card__progress progress::-webkit-progress-bar {
  background-color: #00000030;
  border-radius: 100px;
}

.card__progress progress::-webkit-progress-value {
  background-color: #000;
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
}

/* .workExperienceProgress {
  padding: 0 40px;
} */

.card__indicator {
  font-weight: 500;
  font-size: 14px;
  text-align: end;
}

.card__indicator-amount {
  font-size: 18px;
}

.workHours {
  padding: 0 30px;
  border: 2px solid #9b9898;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.workHours .work-hour {
  font-size: 22px;
  font-weight: bold;
}

.workComment {
  letter-spacing: 1px;
}

@media screen and (max-width: 1279px) {
  .workExperience {
    margin-top: 100px !important;
  }
}
