/* message section*/
#messageCentre {
  padding: 50px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#controlButtons {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 60px !important; */
  /* justify-content: end; */
}

#listSection {
  display: flex;
  flex-direction: column;
}

#listConfig,
#controlButtons #playerConfig {
  margin-right: 0px;
}

#messageSection {
  width: 100%;
}

.messageBox {
  border: 2px solid #006b00;
  width: calc(100% - 84px);
  padding: 40px;
  resize: none;
  overflow-y: scroll;
  height: 32vh;
}

#headerImg {
  width: 2560px;
  height: 115px;
  display: flex;
}

#connectionDiv {
  width: 90%;
  display: flex;
  height: calc(100vh - 70px);
  overflow-y: scroll;
}

#sendMsgBtn {
  margin-right: 0;
}

.historyBox {
  padding: 10px;
  border: 2px solid #006b00;
  margin-top: 15px;
  width: calc(100% - 24px);
  float: left;
}

.historyLink {
  float: left;
  margin-top: 15px;
  background-color: #fff;
  color: #006b00;
  text-transform: uppercase;
  padding: 20px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
  cursor: pointer;
  padding-left: 0;
}

#error {
  color: red;
  display: inline-block;
  padding-top: 6px;
}

.historyLink:hover {
  color: #006b00;
}

@media screen and (max-width: 1280px) {
  #messageCentre {
    order: 2;
    width: calc(100% - 60px);
  }
  #messageSection {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1025px) {
  #controlButtons {
    flex-direction: column;
    width: 100%;
    /* margin-bottom: 0px; */
  }
}

@media screen and (max-width: 1025px) {
  .dropdown-content {
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (max-width: 1025px) {
  .messageControlButton {
    flex-direction: column;
    width: 100%;
  }
}

/* ------------------------ */
/*  */

.dropbtn {
  background-color: #006b00;
  text-align: center;
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
  cursor: pointer;
  min-width: 160px;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdownbtn {
  background-color: white;

  text-align: center;
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
  cursor: pointer;
  min-width: 160px;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

/* .dropdown-content button {
  color: #3e8e41;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
} */

.dropdown-content button:hover {
  background-color: #3e8e41;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

.dropdown:hover .dropbtn {
  background-color: #f8f8f8;
  color: #3e8e41;
}
