.ExistingControlBtn {
  padding: 30px;
  width: 85%;
  /* margin-top: 60px; */
}

.ExistingText {
  font-size: 25px;
  color: var(--primary-color);
  margin-bottom: 20px;
  /* margin-top: 80px; */
  /* margin-left: 50px; */
}

.DropDownControlBtn {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 41px;
  display: flex;
  justify-content: left !important;
}

/* DropDown */

.dropbtn {
  /* background-color: #006b00; */
  background-color: white;
  text-align: center;
  /* color: white; */
  color: var(--primary-color);
  /* padding: 16px; */
  font-size: 16px;
  border: none;
  cursor: pointer;
  min-width: 140px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownbtn {
  background-color: white;
  text-align: center;
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.dropbtn .dropdown-content button {
  /* color: #006b00; */
  color: var(--primary-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content button:hover {
  /* background-color: #006b00; */
  background-color: var(--primary-color);
}

.dropdown:hover .dropbtn {
  background-color: #f8f8f8;
  color: var(--primary-color);
}

.active {
  display: block !important;
}

#controlButtons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  /* margin-bottom: 60px; */
  height: 50px;
  /* width: 100%; */
  /* margin-top: 66px; */
  /* margin-right: 69px; */
}

.instituteTypeBtn {
  width: 235px;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin: 0;
}

#SubDropdown {
  width: 235px;
  border-top: none !important;
  text-align: left;
  margin: 0 !important;
}

@media screen and (max-width: 1025px) {
  #controlButtons {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    /* margin-left: 31px;  */
    /* margin-bottom: 0px; */
  }
}

@media screen and (max-width: 1025px) {
  .ExistingControlBtn {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 1025px) {
  #SubDropdown {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1025px) {
  .instituteTypeBtn {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1025px) {
  .dropdown-content {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 1025px) {
  .controlBtn {
    margin-top: 0 !important;
    /* margin-bottom: 0 !important; */
  }
}

@media screen and (max-width: 1025px) {
  .ExistingText {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 120px;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .ExistingText {
    margin-top: 15px;
  }
}