/* .sectionTitle {
  color: black;
  padding-bottom: 2px;
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
}

.collapsible {
  background-color: #006b00;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: calc(100% - 36px);
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
}

.active2,
.collapsible:hover {
  background-color: #006b0080;
}

.content {
  padding: 0px 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #006b0020;
  width: calc(100% - 36px);
}

.collapsible:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active2:after {
  content: "\2212";
} */

.border0 {
  color: black;
  border: 0;
  /*background-color: white;*/
}

.qualificationGrading .p-dropdown-label {
  padding: 1.5px 5px !important;
}

.courseTitleQualification {
  font-weight: 600;
  font-size: 16px;
}

.unitTitle {
  font-size: 16px;
  font-weight: 500;
}

.moduleTitle {
  font-size: 15px;
}

.courseCollapsible {
  /* background-color: #006b00; */
  /* color: white; */
  cursor: pointer;
  /* padding: 18px; */
  /* width: calc(100% - 36px); */
  /* border: none; */
  /* text-align: left; */
  /* outline: none; */
  /* font-size: 20px; */
}

/* .active2,
.courseCollapsible:hover {
  background-color: #006b0080;
} */

.courseCollapsible:after {
  content: "\002B";
  color: rgb(160, 160, 160);
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.activeCourse:after {
  content: "\2212";
}

.courseContent {
  /* padding: 0px 18px; */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  /* background-color: #006b0020; */
  /* width: calc(100% - 36px); */
}

.max-height-0 {
  max-height: 0;
}

@media screen and (max-width: 1557px) {
  .qualificationCoursesContent .p-dropdown {
    /* width: 10rem !important; */
  }
}

@media screen and (max-width: 481px) {
  .qualificationCoursesContent .qualificationGradingDiv {
    flex-direction: column;
    padding: 2px;
    align-items: inherit !important;
  }
  /* .qualificationCoursesContent .p-dropdown {
    width: 100% !important;
  } */
}

@media screen and (max-width: 1400px) {
  .qualificationGradingDiv * {
    box-sizing: border-box !important;
  }
}

@media screen and (max-width: 767px) {
  .qualificationCoursesContent .unitTitle {
    margin-bottom: 12px !important;
  }
  .qualificationCoursesContent .moduleTitle {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 1629px) and (min-width: 992px) {
  .qualificationCoursesContent .unitTitle {
    margin-bottom: 12px !important;
  }
  .qualificationCoursesContent .moduleTitle {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 1629px) and (min-width: 1400px) {
  .qualificationCoursesContent .qualificationUnitGrading {
    width: 99%;
    box-sizing: border-box !important;
  }
  .qualificationCoursesContent .qualificationModuleGrading {
    width: 99%;
    box-sizing: border-box !important;
  }
}
